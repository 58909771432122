<div class="full-page container-fluid w-100 p-0">
  <div class="overflow-hidden p-0">
    <div class="row m-0 vh-100">
      <div class="col-12 col-lg-6 p-0 login-banner">
        <img src="/assets/images/login-registration/jets-login-banner.jpg" alt="" />
      </div>
      <div class="col-12 user-login-panel col-lg-6 text-start bg-white">
        <div class="row h-100">
          <div class="col-12">
            <div class="content">
              <label class="title text-primary">{{ 'login.title' | translate }}</label>
              <div class="panel-text mlb-text font-size-14">
                {{ 'login.welcome.message' | translate }}
              </div>
              <fgb-tm-user-login></fgb-tm-user-login>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
